import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  public list = [
    {img: '../../../assets/img/partner1.svg', text: 'Магазини матеріалів для ремонту та декору'},
    {img: '../../../assets/img/partner2.svg', text: 'Будівельні супермаркети'},
    {img: '../../../assets/img/partner3.svg', text: 'Павільйон на ринку'},
    {img: '../../../assets/img/partner4.svg', text: 'Магазини шпалер'},
    {img: '../../../assets/img/partner5.svg', text: 'Інтернет-магазини, які займаються продажем матеріалів для ремонту та декору'}
  ];

  constructor() { }

  ngOnInit(): void {
  }

  scroll(el) {
    document.getElementsByClassName(el)[0].scrollIntoView();
  }

}
