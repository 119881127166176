import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  checker = [true, false, false];
  constructor() { }

  ngOnInit(): void {
  }

  plus() {
    if (this.checker[0]) {
      this.checker[1] = true;
      this.checker[0] = false;
    } else if (this.checker[1]) {
      this.checker[2] = true;
      this.checker[1] = false;
    }
    var video = document.getElementsByTagName('video')[0];
    video.load();
  }

  cons() {
    if (this.checker[2]) {
      this.checker[1] = true;
      this.checker[2] = false;
    } else if (this.checker[1]) {
      this.checker[0] = true;
      this.checker[1] = false;
    }

    var video = document.getElementsByTagName('video')[0];
    video.load();
  }
}
