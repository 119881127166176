<div class="partner-block">
  <h2>Хто може стати нашим партнером</h2>
  <!-- <h3>Ідеальний варіант для Ваших клієнтів, які роблять ремонт самостійно! Хороший прибуток для Вас - від 250 грн з роздрібного замовлення.</h3> -->
  <div class="center-block">
    <div class="card-block" *ngFor="let item of list">
      <img src="{{ item.img }}" alt="" />
      <p>
        {{ item.text }}
      </p>
    </div>
  </div>
  <div class="partner-btn">
    <div class="gradient-border">
      <button class="btn-orange" (click)="scroll('catalog-block')">
          Отримати пропозицію
      </button>
    </div>
  </div>
  <h3>
    Ідеальний варіант для Ваших клієнтів, які роблять ремонт самостійно!
  </h3>
</div>
