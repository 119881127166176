<div class="footer-content">
  <!-- <img src="../../../assets/img/footer-logo.svg" alt="" /> -->
  <div class="contact-block">
    <div class="icon-block">
      <p>
        Зателефонуйте нам:
      </p>
      <button>
        <img src="../../../assets/img/red-phone.svg" alt="" />
        <a href="tel:067-391-80-93">067-391-80-93</a>
      </button>
    </div>
    <p>Адреса: <span>м Дніпро, вул. Краснопільська, 17</span></p>
    <div class="icon-block">
      <p>
        Ми у соцмережах:
      </p>
      <a href="https://www.instagram.com/dropoptua" class="iconka"
        ><img src="../../../assets/img/instagram.svg" alt=""
      /></a>
      <a href="https://www.facebook.com/dropoptua" class="iconka"
        ><img src="../../../assets/img/facebook.svg" alt=""
      /></a>
      <a href="https://t.me/joinchat/wsf-CR1LbsY4MDZi" class="iconka"
        ><img src="../../../assets/img/telegram.svg" alt=""
      /></a>
    </div>
    <p>
      Для покупки в роздріб :
      <a href="https://stickerwall.com.ua/" class="a-text"
        >https://stickerwall.com.ua/</a
      >
    </p>
  </div>
</div>
