<div class="landing-content">
  <div class="uppper-block">
    <div class="left-block">
      <h2>Для магазинів будматеріалів та шпалер!</h2>
      <h2>Самоклеючі 3D панелі.</h2>
      <h2>ОПТ від прямого імпортера.</h2>
      <p>
        Заробляйте до 50% націнки на продажі.
      </p>
      <p>
        340 000+ високоліквідних товарів для ремонту та декору завжди в
        наявності.
      </p>
      <div class="gradient-border">
        <button class="btn-orange" (click)="scroll('catalog-block')">
          Отримати каталог з оптовими цінами
        </button>
      </div>
    </div>
  </div>
</div>
