<div class="contact-block">
  <h2 *ngIf="value">{{ h2 }}</h2>
  <h2 *ngIf="!value">{{ h2x }}</h2>
  <h3 *ngIf="value">{{ h3 }}</h3>
  <h3 *ngIf="!value">{{ h3x }}</h3>
  <div class="contact-btn">
    <button>
      <a href="tel:067-391-80-93">Зв'язатися з нами</a>
    </button>
    <button>
      <a href="mailto: orlovskiy.stas3d@gmail.com">Отримати консультацію</a>
    </button>
  </div>
</div>
