<div class="video-content">
  <h2>Відео</h2>
  <h3>Подивіться відео про наші товари</h3>
  <div class="carusel">
    <button (click)="cons()"><</button>
    <div class="video-block">
      <video
        preload="auto"
        playsinline
        [muted]="true"
        [autoplay]="true"
        [loop]="true"
        id="myVideo"
      >
        <source
          src="../../../assets/video/video1.mp4"
          type="video/mp4"
          *ngIf="checker[0]"
        />
        <source
          src="../../../assets/video/video2.mp4"
          type="video/mp4"
          *ngIf="checker[1]"
        />
        <source
          src="../../../assets/video/video3.mp4"
          type="video/mp4"
          *ngIf="checker[2]"
        />

        Your browser does not support HTML5 video.
      </video>
      <div class="d-f">
        <div [ngClass]="checker[0] ? 'big-dot' : 'dot'"></div>
        <div [ngClass]="checker[1] ? 'big-dot' : 'dot'"></div>
        <div [ngClass]="checker[2] ? 'big-dot' : 'dot'"></div>
      </div>
    </div>
    <button (click)="plus()">></button>
  </div>
  <h3>Подивіться на переваги нашого товару в дії. Це займе у Вас всього пару хвилин.</h3>
</div>
