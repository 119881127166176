import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profit',
  templateUrl: './profit.component.html',
  styleUrls: ['./profit.component.scss']
})
export class ProfitComponent implements OnInit {

  public list = [
    'Це швидкий та легкий ремонт власноруч без додаткових витрат',
    'Амортизують удар',
    'В наявності понад 100 фактур та кольорів сучасних дизайнів',
    'Створюють шумоізоляцію',
    'Клеяться на будь-яку поверхню і приховують нерівності стін',
    'Можна мити миючими засобами',
    'Відштовхують вологу',
    'Виключають появу грибка',
    'Утеплюють',
    'Не поглинають рідину, не вбирають запахи, не змінюють колір'
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
