<div class="advantage-content">
  <h2>Наші переваги</h2>

  <div class="carusel">
    <button (click)="cons()"><</button>
    <div *ngFor="let item of showList">
      <div class="img-block">
          <img src="{{ item.img }}" alt="" />
      </div>
      <p>{{ item.text }}</p>
    </div>
    <button (click)="plus()">></button>
  </div>
</div>
