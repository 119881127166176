import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advantage',
  templateUrl: './advantage.component.html',
  styleUrls: ['./advantage.component.scss']
})
export class AdvantageComponent implements OnInit {

  public list = [
    {img: '../../../assets/img/advantage7.svg', text: '№1 на ринку самоклеючих панелей'},
    {img: '../../../assets/img/advantage1.svg', text: 'Понад 12 років досвiду'},
    {img: '../../../assets/img/advantage2.svg', text: '340 000+ одиниць товару в наявності'},
    {img: '../../../assets/img/advantage3.svg', text: 'Націнка на товарах до 50%'},
    {img: '../../../assets/img/advantage4.svg', text: 'Безкоштовні зразки'},
    {img: '../../../assets/img/advantage5.svg', text: 'Швидка обробка замовлень'},
    {img: '../../../assets/img/advantage6.svg', text: 'Доставка з післяплатою'},
    {img: '../../../assets/img/advantage8.svg', text: 'Можливість стати ексклюзивним представником у регіоні'},
    ];

    public start = 0;
    public showList = [];
    public countItem = 0;
    public test = window.innerWidth;

  constructor() { }

  ngOnInit(): void {
    if (window.innerWidth < 1000) {
      this.showList.push(this.list[0]);
    } else {
      this.showList.push(this.list[0]);
      this.showList.push(this.list[1]);
      this.showList.push(this.list[2]);
      this.showList.push(this.list[3]);
    }

    this.countItem = this.showList.length;

  }

  cons() {
    if (this.start <= 0) return
    this.start -= 1;
    this.showList.pop();
    this.showList.unshift(this.list[this.start]);
  }

  plus() {
    if (this.start + this.countItem >= this.list.length) return
    this.start += 1;
    this.showList.shift();
    this.showList.push(this.list[this.start +  this.countItem - 1]);
  }

}
