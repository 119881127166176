<div class="profit-block">
  <h2>Чому 3D панелі будуть мати попит у Ваших клієнтів?</h2>
  <div class="profit-list">
    <div class="list-items" *ngFor="let item of list">
      <img src="../../../assets/img/bird.svg" alt="">
      <p >{{item}}</p>
    </div>

  </div>
</div>
