<app-header></app-header>
<app-landing></app-landing>
<app-partner></app-partner>
<app-profit></app-profit>
<app-video></app-video>
<app-advantage></app-advantage>
<app-contact-us [value]="dark"></app-contact-us>
<app-catalog></app-catalog>
<app-contact-us [value]="green"></app-contact-us>
<app-footer></app-footer>
