import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  @Input() value: boolean;
  public h2 = 'Отримайте консультацію від нашого фахівця';
  public h3 = 'Або зателефонуйте:';
  public h2x = 'Зворотній зв’язок';
  public h3x = 'Залишилися питання - задайте їх через форму нижче';

  constructor() { }

  ngOnInit(): void {
  }

}
