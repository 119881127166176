import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public show = false;

  constructor() { }

  ngOnInit(): void {
  }

  scroll(el) {
    this.show = false;
    document.getElementsByClassName(el)[0].scrollIntoView();
  }

  rotate() {
    this.show = !this.show;
    document.getElementsByClassName("container")[0].classList.toggle("change");
  }
}
