<div class="header-content">
  <img src="" alt="" />
  <div class="btn-header">
    <button class="header-btn" (click)="scroll('partner-block')">
      Партнери
    </button>
    <button class="header-btn" (click)="scroll('advantage-content')">
      Переваги
    </button>
    <button class="header-btn" (click)="scroll('catalog-block')">
      Каталог
    </button>
    <!-- <button class="header-btn" (click)="scroll('about-content')">
      Відгуки
    </button> -->
    <button class="header-btn" (click)="scroll('footer-content')">
      Контакти
    </button>
    <button class="phone-btn"><a href="tel:067-391-80-93"></a></button>
  </div>
</div>

<div class="header-div-small">
  <div class="d-f">
    <div class="container" (click)="rotate()">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div *ngIf="show" class="side-menu" (mouseleave)="rotate()">
        <button class="header-btn" (click)="scroll('partner-block')">
          Партнери
        </button>
        <button class="header-btn" (click)="scroll('advantage-content')">
          Переваги
        </button>
        <button class="header-btn" (click)="scroll('catalog-block')">
          Каталог
        </button>
        <!-- <button class="header-btn" (click)="scroll('about-content')">
          Відгуки
        </button> -->
        <button class="header-btn" (click)="scroll('footer-content')">
          Контакти
        </button>
      </div>
    </div>

    <!-- <img src="../../../assets/img/logo.svg" alt="" /> -->
  </div>
  <a href="tel:067-391-80-93"  class="phone-btn"></a>
</div>
