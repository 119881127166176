import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {

  public list: any[] = [
    [{ img: '../../../assets/3dPanelsLaminat/1.jpg' }],
    [{ img: '../../../assets/laminat/1.jpg' }],
    [{ img: '../../../assets/florPazzle/1.jpg' }]
  ];
  public showList = [[], [], []];
  public start = [1, 1, 1];

  constructor() {}

  ngOnInit() {
    for (let i = 2; i < 43; i++) {
      this.list[0].push({ img: '../../../assets/3dPanelsLaminat/' + i + '.jpg' });
    }

    for (let i = 2; i < 20; i++) {
      this.list[1].push({ img: '../../../assets/laminat/' + i + '.jpg' });
    }

    for (let i = 2; i < 29; i++) {
      this.list[2].push({ img: '../../../assets/florPazzle/' + i + '.jpg' });
    }


    this.showList[0].push(this.list[0][0]);
    this.showList[1].push(this.list[1][0]);
    this.showList[2].push(this.list[2][0]);
  }

  cons(num) {
      if (this.start[num] <= 0) return
      this.start[num] -= 1;
      this.showList[num].pop();
      this.showList[num].unshift(this.list[num][this.start[num]]);
  }

  plus(num) {
      if (this.start[num]  >= this.list[num].length) return
      this.start[num] += 1;
      this.showList[num].shift();
      this.showList[num].push(this.list[num][this.start[num] - 1]);
  }
}
