import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { AdvantageComponent } from './component/advantage/advantage.component';
import { LandingComponent } from './component/landing/landing.component';
import { PartnerComponent } from './component/partner/partner.component';
import { ProfitComponent } from './component/profit/profit.component';
import { VideoComponent } from './component/video/video.component';
import { CatalogComponent } from './component/catalog/catalog.component';
import { AboutUsComponent } from './component/about-us/about-us.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactUsComponent,
    AdvantageComponent,
    LandingComponent,
    PartnerComponent,
    ProfitComponent,
    VideoComponent,
    CatalogComponent,
    AboutUsComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
