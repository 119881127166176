<div class="catalog-block">
  <h2>Каталог товарів</h2>
  <div class="center">
    <div class="card-catalog">
      <div class="carusel">
        <button (click)="cons(0)"><</button>
        <div *ngFor="let item of showList[0]">
          <img src="{{ item.img }}" alt="" />
        </div>
        <button (click)="plus(0)">></button>
      </div>
      <p>Самоклеючі 3D панелі та багети</p>
      <a href="mailto: orlovskiy.stas3d@gmail.com">Отримати каталог</a>
    </div>
    <div class="card-catalog">
      <div class="carusel">
        <button (click)="cons(1)"><</button>
        <div *ngFor="let item of showList[1]">
          <img src="{{ item.img }}" alt="" />
        </div>
        <button (click)="plus(1)">></button>
      </div>
      <p>Вінілова плитка та ламінат</p>
      <a href="mailto: orlovskiy.stas3d@gmail.com">Отримати каталог</a>
    </div>
    <div class="card-catalog">
      <div class="carusel">
        <button (click)="cons(2)"><</button>
        <div *ngFor="let item of showList[2]">
          <img src="{{ item.img }}" alt="" />
        </div>
        <button (click)="plus(2)">></button>
      </div>
      <p>Підлога-пазл</p>
      <a href="mailto: orlovskiy.stas3d@gmail.com">Отримати каталог</a>
    </div>
  </div>
</div>
